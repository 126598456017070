import React from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Typography } from '@material-ui/core'
import useStyles from './LoginModal.styles'

export default function TransitionsModal({ open, handleClose }) {
  const classes = useStyles()

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Container className={classes.container} maxWidth="xs">
              <Typography
                variant="h4"
                align="center"
                className={classes.welcome_text}
              >
                Bienvenido a la plataforma
              </Typography>
              <form>
                <Grid container spacing={3} justify="center">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Password"
                          name="password"
                          size="small"
                          type="password"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <Button
                      color="primary"
                      fullWidth
                      type="submit"
                      variant="contained"
                    >
                      Ingresar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
