import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ImgMediaCard from '../../components/StoryCards'
import ButtonAppBar from '../../components/Header'

const useStyles = makeStyles({
  wrap: {
    marginTop: '20px'
  }
})

const Home = () => {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      <Grid item>
        <ButtonAppBar />
      </Grid>
      <Grid item container>
        <Grid item xs={false} sm={2} />
        <Grid item xs={12} sm={8}>
          <Grid className={classes.wrap} container justify="center" spacing={6}>
            <Grid item>
              <ImgMediaCard />
            </Grid>
            <Grid item>
              <ImgMediaCard />
            </Grid>
            <Grid item>
              <ImgMediaCard />
            </Grid>
            <Grid item>
              <ImgMediaCard />
            </Grid>
            <Grid item>
              <ImgMediaCard />
            </Grid>
            <Grid item>
              <ImgMediaCard />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center">
                Ver mas
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} sm={2} />
      </Grid>
    </Grid>
  )
}

export default Home
