import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import useStyles from './Header.styles'
import LoginModal from '../LoginModal/LoginModal'

export default function ButtonAppBar() {
  const [open, setOpen] = React.useState(false)

  const classes = useStyles()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            BLOG LITERARIO
          </Typography>
          <Button
            variant="outlined"
            color="inherit"
            className={classes.search}
            onClick={handleOpen}
          >
            Ingresar
          </Button>
          <SearchIcon />
        </Toolbar>
      </AppBar>
      <LoginModal open={open} handleClose={handleClose} />
    </div>
  )
}
